import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const News = () => {
  const [media, setMedia] = useState([]);
  const [imgHeight, setImgHeight] = useState(null);

  const handleImageLoad = (e) => {
    setImgHeight(e.target.offsetHeight);
  };

  useEffect(() => {
    const fetchMedia = async () => {
      try {
        const response = await fetch(
          `https://graph.instagram.com/me/media?fields=media_url,thumbnail_url,media_type,permalink&access_token=IGQWRNcXpiSDhLQTl3Skl5ZAUdiaHl5RjVnWVNFN2pIeGt4TXY0N25BSTd5Sm9hcWp1OVZASY2ZAtSm9sY3pzVV8tc1NUaVdKVGl3WElzT1Y0QnVTMy13dGYwR3c5UXp6WUxEUkl2VkduZAm1fRWM1YUM5ZAUhxRDZARMzgZD`
        );
        if (response.ok) {
          const data = await response.json();

          console.log("data", data);

          // Filtra solo imágenes y videos
          const filteredMedia = data.data.filter(
            (item) => item.media_type === "IMAGE" || item.media_type === "VIDEO"
          );
          setMedia(filteredMedia);
        } else {
          console.error("Failed to fetch media:", response.statusText);
        }
      } catch (error) {
        console.error("Error fetching media:", error);
      }
    };

    fetchMedia();
  }, []);

  const settings = {
    autoplay: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    draggable: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };

  return (
    <section className="carousel-section">
      <div className="container">
        <h2 className="h-medium">Actualidad</h2>
        <div className="carousel-section__container">
          <Slider {...settings}>
            {media.map(
              ({ media_url, media_type, permalink, thumbnail_url, id }) => (
                <div className="carousel-section__item" key={id}>
                  <a href={permalink} target="_blank" rel="noopener noreferrer">
                    {media_type === "VIDEO" ? (
                      <div
                        className="carousel-section__img"
                        style={{
                          backgroundImage: `url(${thumbnail_url})`,
                          height: imgHeight || "auto",
                        }}
                      ></div>
                    ) : (
                      <img
                        src={media_url}
                        onLoad={handleImageLoad}
                        style={{ height: imgHeight || "auto" }}
                        alt="Instagram Media"
                      />
                    )}
                  </a>
                </div>
              )
            )}
          </Slider>
        </div>
      </div>
    </section>
  );
};

export default News;
