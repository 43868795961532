import React from "react";
import ReactDOM from "react-dom";
import { App } from "./components";
import { createRoot } from "react-dom/client";

// import "owl.carousel/dist/assets/owl.carousel.css";
// import "owl.carousel/dist/assets/owl.theme.default.css";
import "./styles/styles.scss";

const root = createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
